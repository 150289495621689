var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-12 col-md-12 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_vm.wasiat ? _c('div', {
    staticClass: "row"
  }, [!_vm.isEkycCompleteWasiat && _vm.failedCountWasiat <= 0 ? _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('ekyc')], 1) : _vm._e(), !_vm.isEkycCompleteWasiat && _vm.failedCountWasiat > 0 ? _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('manual-ekyc')], 1) : _vm._e(), _vm.isEkycCompleteWasiat ? _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('ekyc-complete')], 1) : _vm._e()]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }